import React from "react"
import { Link } from "gatsby"
import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import 'twin.macro'


const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404: Not found" />
    <h1 tw="text-4xl font-bold pb-8">404: Not Found</h1>
    <p tw="text-sm py-2 leading-relaxed">Oops, that didn't go well. You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p tw="text-sm py-2 leading-relaxed">You can start over from the <Link to="/" tw="underline">main page</Link>.</p>
  </PageLayout>
)

export default NotFoundPage
