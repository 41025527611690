import React from 'react'
import { GlobalStyles } from 'twin.macro'

const PageLayout = ({ children }) => {

    return (
        <div tw="flex flex-col items-start justify-items-center h-screen font-mono">
            <GlobalStyles />
            <div tw="mx-auto items-start justify-start relative py-10 md:w-4/5 lg:w-2/3 xl:w-1/2">
                <div tw="relative px-4 sm:px-10">
                    <div tw="mx-auto">
                        <main>{children}</main>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageLayout
